<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
        
            <div class="MBtextcont">
                <p>
                    Вот и настал день, когда мы с вами начинаем работу с пластиком. Ура!
                    Наконец-то начинается самое интересное.
                </p>
                <p>
                    Сегодня мы проанализируем все, что мы прошли и сделаем основу для
                    головы. От вас потребуется не просто повторить за мной весь урок, а
                    очень хорошо подумать именно над своим образом и особенностями строения
                    его головы и лица.
                </p>
            </div>

            <VideoView video-id="fc5021c01a234bf9bce5f94205f0b680"/>

            <div class="MBtextcont">
                <p class="nomargin">Нам понадобится:</p>
                <ul>
                    <li>Пластилин</li>
                    <li>Палочки для суши</li>
                    <li>Пищевая пленка</li>
                    <li>Стеки, нож или стек гладилка</li>
                    <li>Пластик La Doll</li>
                    <li>Глаза или шарики для глаз</li>
                    <li>Лист бумаги А4, карандаш, линейка</li>
                    <li>Циркуль</li>
                </ul>
            </div>
        
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Лепим череп", url:"/mycourses/head/1"},
                { title: "Делаем обрубовку", url:"/mycourses/head/2"},
                { title: "Лепим лицо", url:"/mycourses/head/3"},
                { title: "Основа из La Doll", url:"/mycourses/head/4"},
                { title: "Создаем объемы", url:"/mycourses/head/5"},
                { title: "Доработка деталей", url:"/mycourses/head/6"},
            ],
        }
        }
    }
</script>